import React from 'react';
import { graphql } from 'gatsby';

import SEO from 'components/Seo';
import Footer from 'components/Footer';

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';

import CanstarInfo from 'components/cards/CanstarInfo';
import Faq from 'components/common/Faq';
import FamilyFriendsOfferHeroBanner from 'components/cards/FamilyFriendsOfferHeroBanner';
import CarbonOffsetInfo from "components/cards/CarbonOffsetInfo";
import FlybuysInfo from 'components/cards/FlybuysInfo';
import Ng40DiscOffer from 'components/cards/Ng40DiscOffer';
import NgSwitchInfo from 'components/cards/NgSwitchInfo';

import Image from 'components/common/Image';
import faqData from 'constants/components/accordion-list/family-friends-offer-faq';

import seoImage from 'images/meta/lego-family-friends.jpg';
import 'styles/templates/employee-discount-offer.css';

export const query = graphql`
	query($slug: String!) {
		friendsFamilyOfferJson(slug: {eq: $slug}) {
			slug,
			company,
			promoCode,
			logo
		}
	}
`;


const familyFriendsOfferPage = ({ data }) => {

	const familyFriendsOffer = data.friendsFamilyOfferJson;
	const faqSettings = {openFirstItem: true};

	// add dynamic accordionList to faqData
	let accordionList = [{
							"title": "I'm already a Kleenheat customer, can I redeem this offer?",
							"content": `<p>We are pleased to offer existing Kleenheat customers who are the family or friend of a ${familyFriendsOffer.company} employee the same <strong>40% off natural gas usage charges for 12 months*</strong> deal.
							Simply log into your <a href='/login' title='Log in'>My Account</a> and enter the promo code <strong>${familyFriendsOffer.promoCode}</strong> to automatically increase your discount offer.</p><p>Alternatively, you can contact our customer service team on <a href='tel:132180' title='Contact our customer service team'>13 21 80</a> who will be able to assist you.</p>`
						}];
	let modifiedAccordionList = accordionList.concat(faqData);

	return (
		<>
			<SEO title={`${familyFriendsOffer.company} Family and Friends discount offer | Kleenheat`}
            	 description={`Family and friends of ${familyFriendsOffer.company} employees can save 40% on natural gas usage charges for 12 months*.`}
				 meta={[{name:"robots", content:'noindex'}]}
				 image={seoImage} />

        	<LayoutMain>

        		<FamilyFriendsOfferHeroBanner promoCode={familyFriendsOffer.promoCode}
					                             company={familyFriendsOffer.company} />

				<LayoutPanel theme="mer-theme--light"
	        				 background="mer-bg--ui-light"
		                	 padding="mer-pt-ju mer-pb-sm"
		                	 id="more">
		            <div className="container">
		            	<div className="row mer-panel align-items-center justify-content-center">
		            		<div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg"> 
			                	<div className="content text-center">
			                		{familyFriendsOffer.logo !== '' && 
			                			<Image className={`mer-panel__image company-logo ${familyFriendsOffer.slug}`}
		                                 	   src={familyFriendsOffer.logo}
			                                   alt="Logo" />
			                		}
		                			<h1 className="mt-2 mer-color--primary-cyan">Family and friends discount offer</h1> 
	                			</div> 
                			</div>
		            	</div>
		            </div>
		        </LayoutPanel>

		        <Ng40DiscOffer panelBackground="mer-bg--ui-light"
            			 	 panelPadding="mer-py-de"
            			 	 company={familyFriendsOffer.company}
            			 	 familyFriends={true} />

				<CarbonOffsetInfo  panelBackground="mer-bg--ui-light"
	            		  		   panelPadding="mer-py-de" />

        		<FlybuysInfo panelBackground="mer-bg--ui-light"
            			 	 panelPadding="mer-py-de" />

	            <CanstarInfo panelBackground="mer-bg--ui-light"
	            			 panelPadding="mer-pt-de mer-pb-ju"
	            			 ngAvailable={true} />

  			    <Faq panelBackground="mer-bg--gs-grey-lighter"
		  			 panelPadding="mer-py-lg"
		  			 id="faq"
		  			 data={modifiedAccordionList}
					 settings={faqSettings} />
				

    			<NgSwitchInfo theme="mer-theme--cyan"
    						  panelBackground="mer-bg--primary-cyan"
	            			  panelPadding="mer-py-lg"
	            			  promoCode={familyFriendsOffer.promoCode} />
        	</LayoutMain>
            <Footer />
		</>
		
	);
}

export default familyFriendsOfferPage;